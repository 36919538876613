import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const sharedStore = namespace('Shared')

@Component
export default class GlobalLoader extends Vue {
  @sharedStore.State
  public globalLoader: boolean
}
