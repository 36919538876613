import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Websocket } from '@/App/Websockets'
import ConfirmDialog from './ConfirmDialog/Confirm-Dialog.vue'
import GlobalLoader from '@/App/Components/GlobalLoader/Global-Loader.vue'

const sharedStore = namespace('Shared')

@Component({
  components: { ConfirmDialog, GlobalLoader }
})
export default class MainLayout extends Vue {
  @sharedStore.Mutation
  private setConfirmDialog: (confirmDialog: any) => void

  private websocket: Websocket

  public logout(): void {
    this.websocket.socket.disconnect()
  }

  private mounted(): void {
    this.websocket = new Websocket()
    this.setConfirmDialog(this.$refs.confirmDialog)
  }
}
