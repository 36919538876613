import io from 'socket.io-client'
import { BASE_URL } from '@/App/Constants'
import store from '@/store'
import {
  ONLINE,
  WIDGET_DATA_UPDATED,
  DEVICE_DATA_UPDATED,
  NOTIFICATION_TRIGGERED,
  NOTIFICATION_TRIGGERED_COUNT_INCREASE,
  NOTIFICATION_TRIGGERED_COUNT_DECREASE,
  USER_ONLINE,
  USER_OFFLINE
} from '@/App/Websockets/actions'
import { DeviceDataUpdated, TriggeredNotification, WidgetDataUpdated } from '@/App/Websockets/action-payload.interface'
import { UserOnline } from '@/App/Services/interfaces/admin.interface'

export class Websocket {
  public socket: any

  constructor() {
    this.socket = io(BASE_URL, { transports: ['websocket'] })

    this.init()
  }

  private init() {
    this.socket.on('connect', () => {
      this.onlineRequest()

      this.socket.on(WIDGET_DATA_UPDATED, ({ payload }: WidgetDataUpdated) => {
        store.commit('Widget/updateWidget', {
          _id: payload.widgetId,
          lastValue: payload.lastValue,
          lastUpdateTime: payload.lastUpdateTime
        })
      })

      this.socket.on(DEVICE_DATA_UPDATED, ({ payload }: DeviceDataUpdated) => {
        if (store.state.Device.devices.length) {
          store.commit('Device/updateDeviceSensor', {
            deviceId: payload.deviceId,
            sensorId: payload.sensorId,
            sensorName: payload.sensorName,
            lastValue: payload.lastValue,
            lastUpdateTime: payload.lastUpdateTime
          })
        }
        if (store.state.Location.devices.length) {
          store.commit('Location/updateDeviceSensor', {
            deviceId: payload.deviceId,
            sensorId: payload.sensorId,
            sensorName: payload.sensorName,
            lastValue: payload.lastValue,
            lastUpdateTime: payload.lastUpdateTime
          })
        }
      })

      this.socket.on(NOTIFICATION_TRIGGERED, ({ payload }: TriggeredNotification) => {
        store.commit('Shared/setTriggeredNotification', {
          text: payload.text,
          _id: payload._id
        })
      })

      this.socket.on(NOTIFICATION_TRIGGERED_COUNT_INCREASE, () => {
        store.commit('Shared/setTriggeredNotificationsCount', store.state.Shared.triggeredNotificationCount + 1)
      })

      this.socket.on(NOTIFICATION_TRIGGERED_COUNT_DECREASE, () => {
        store.commit('Shared/setTriggeredNotificationsCount', store.state.Shared.triggeredNotificationCount - 1)
      })

      this.socket.on(USER_ONLINE, (user: UserOnline) => {
        store.commit('Admin/addUserOnline', user)
      })

      this.socket.on(USER_OFFLINE, (uid: string) => {
        store.commit('Admin/removeUserOnline', uid)
      })
    })
  }

  private onlineRequest() {
    const user = JSON.parse(<string>localStorage.getItem('user'))
    this.socket.emit(ONLINE, user.token)
  }
}
